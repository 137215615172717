import { graphql } from 'gatsby'
import React from 'react'

import styled, { css } from 'styled-components'


import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../@one-builder/gatsby-theme-ob-master/components/layout/layout"

import styles from "../@one-builder/gatsby-theme-ob-master/styles/global.module.scss"

const New = ({ data }) => {
  let seo_data = {
    title: `Gallery | {{CompanyName}}`,
    description: `View photos of our recent projects.`,
    page_visible: true,
  }

  const images = data.allFile.edges.map(({ node }) => node.childImageSharp)
  // `images` is an array of objects with `thumb` and `full`

  return (
    <Layout seo_data={seo_data}>
      <div className={styles.album}>
        <div className={styles.container}>
        <h1>Gallery</h1>
          <Gallery images={images}/>
        </div>
      </div>
    </Layout>
  )
};

export const query = graphql`
  query ImagesForGallery {
    allFile(filter: {sourceInstanceName: {eq: "gallery"}}) {
      edges {
        node {
          childImageSharp {
            thumb: fluid(maxWidth: 900, maxHeight: 1200) {
              ...GatsbyImageSharpFluid
            }
            full: fluid(maxWidth: 2048) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`

export default New